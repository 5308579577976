<template>
  <div class="lightbox-agreements">
    <ul class="lightbox-agreements-navigation">
      <li
        :class="navigationClasses('termsOfUse')"
        v-on:click="changeView('termsOfUse')"
      >
        Terms of Use
      </li>
      <li
        :class="navigationClasses('privacyPolicy')"
        v-on:click="changeView('privacyPolicy')"
      >
        Privacy Policy
      </li>
    </ul>

    <div class="lightbox-agreements-content" v-html="content"></div>

    <div class="lightbox-agreements-buttons" v-if="showButtons">
      <MercalaButton
        v-on:button-event="agreementStatus(false)"
        label="I don't agree"
        type="dashboard-cancel"
      />

      <MercalaButton
        v-on:button-event="agreementStatus(true)"
        label="I have read and agree to the terms and policies."
        type="dashboard-ok"
      />
    </div>
  </div>
</template>

<script>
import AngelaAPI from "@/utils/AngelaAPI";
import MercalaButton from "@/components/form/MercalaButton";
export default {
  name: "LightboxAgreements",
  components: {
    MercalaButton,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: String,
      required: true,
    },
    refreshText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      termsView: "",
      content: "This is a very long and boring text",
    };
  },
  methods: {
    agreementStatus(agreed) {
      if (agreed) {
        AngelaAPI.updateUser({ agreements_accepted: true })
          .then((response) => {
            this.$emit("close");
          })
          .catch((error) => {
            // Nothing to handle
          });
      } else {
        this.$emit("logout");
      }
    },
    changeView(view) {
      this.termsView = view;
      this.updateView();
    },
    updateView() {
      this.$store.getters
        .getAgreements(this.$props.refreshText)
        .then((settings) => {
          switch (this.termsView) {
            case "termsOfUse":
              this.content = settings.terms_of_use;
              this.$emit("setLightboxInfo", {
                title: "Terms of Use",
              });
              break;
            case "privacyPolicy":
              this.content = settings.privacy_policy;
              this.$emit("setLightboxInfo", {
                title: "Privacy Policy",
              });
              break;
          }
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    // Classes
    navigationClasses(view) {
      return {
        active: view === this.termsView,
      };
    },
  },
  mounted() {
    this.termsView = this.$props.terms;
    this.updateView();
  },
};
</script>

<style lang="scss">
.lightbox-agreements {
  &-navigation {
    margin-bottom: 16px;

    li {
      display: inline-block;
      margin-right: 10px;
      padding: 15px 35px;
      border-radius: 25px;
      font-size: 1rem;
      background: #f3f3f3;
      color: #979797;

      &:hover {
        background: #979797;
        color: white;
        cursor: pointer;
      }

      &.active {
        background: rgb(255, 30, 30);
        background: linear-gradient(
          -90deg,
          rgba(255, 30, 30, 1) 0%,
          rgba(254, 90, 51, 1) 100%
        );
        color: white;

        &:hover {
          background: rgb(255, 30, 30);
          background: linear-gradient(
            -90deg,
            rgba(255, 30, 30, 1) 0%,
            rgba(254, 90, 51, 1) 100%
          );
          color: white;
          cursor: default;
        }
      }
    }
  }

  &-content {
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 16px;

    p {
      margin-bottom: 0px;
      line-height: 1.5;
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
